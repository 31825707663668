.form{
    background-color: white;
    padding: 2rem;
    border-radius: 20px;
    margin-inline: 4rem;
    margin-block:2rem
    
}
.btnradio{
    margin-right: 1rem;
    margin-left:0.3rem
}
.title{
    margin-block: 0.5rem;
}
.selectInput{
    margin-left: 2rem;
    margin-right: 0.2rem;
}
.retourButton{
    /* background-color:rgba(147, 5, 5, 1) !important; */
    color:white !important;
    width: 10rem !important;
    border: none !important;
    margin-bottom: 3rem !important;
    border-radius: 1rem !important;
    margin-top:5rem !important;
    
    
  
  
  }
  .confirmButton{
    /* background-color: #254484 !important; */
    color:white !important;
    width: 10rem !important;
    border: none !important;
    margin-bottom: 3rem !important;
    border-radius: 1rem !important;
    margin-top:5rem !important
  }
  .RetourCol{
    display: flex;
    justify-content: flex-end;
  }
  .titleRow {
    margin-block: 2rem;
}

.titleCol {
    display: flex;
    justify-content: center;
    align-items: center;
}
.choixSport{
    margin-bottom: 1rem;
}