.form {
    background-color: white;
    padding: 2rem;
    border-radius: 20px;
}

.titre {
    color: #2d2d2d;
    display: flex;
    font-size: 0.8rem;
}

.souscripteurTitle {
    color: #2d2d2d;
    font-family: 'Montserrat semibold' sans-serif;
    font-size: 1.5rem;

}

.inputTitle{
    color: #2d2d2d;
    font-family: 'Montserrat medium' sans-serif;
    font-size: 1.1rem;
    margin-top:1rem;
    display: flex;
}
.inputTitle span {
    color:red;
}