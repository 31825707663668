.age {
    color: #1a5c9e;
    font-family: Serif;
    margin-left: 2rem;
}

.titre {
    font-family: 'Montserrat medium' sans-serif;
    color:#254484
}

.ro {
    margin-left: 2rem;
}

.accordionHeader {
    display: flex;
    height: 1.5rem;
              width: 100%;
              justify-content: space-between;
              align-items: center;
              padding-right: 1rem;
                    
}

.deleteButton {
    background-color: #c9c9c9;
    border: none;
    border-radius: 0.3rem;
    width: 2.5rem;
    height: 2.5rem;
    text-align: center;
    padding-block: 0.4rem;
}
.deleteButton:hover {
    background-color: red;
   
}

.deleteButton svg:hover{
    fill:white
}
.inputTitle{
    color: #2d2d2d;
    font-family: 'Montserrat medium' sans-serif;
    font-size: 1.1rem;
    margin-top:1rem;
    display: flex;
}
.inputTitle span {
    color:red;
}