.section {
  
    padding: 5%;
 
    }
    /*.cardHeader {
      padding-top: 3;
    }
    .header_p2{
      color: #800000;
    }
    .cards{
      background-color: none;
      border: none;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
    }
    .card{
      background-color: rgba(255, 255, 255, 0.9);
      border-radius: 20%;
      padding: 5%;
    } */
  
    .propositionCol{
     text-align: center;
    }
    .propositionText{
      font-family: 'Montserrat bold' sans-serif;
      color:#2D2D2D;
      font-size:1.8rem;
      font-weight: 600;
    }
    .tarificationCol{
      text-align: center;
    }
  
    .tarificationText{
      font-family: 'Montserrat medium' sans-serif;
      color:#2D2D2D;
      font-size: 1rem;
      font-weight: 500 ;
    }
    .box{
      background-color: white;
      /* width:90%; */
      height: fit-content; 
      border-radius: 0.8rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 70rem;
      /* margin-left: auto;
  margin-right: auto; */
  padding-top: 1.5rem;
  
  
  
    }
  
    .switchComponent{
      display: flex;
      flex-direction: row;
  
    }
  
  
  
  
  .mensuelleChecked{
    color:#254484;
    font-family: 'Montserrat bold' sans-serif;
    font-size:1.5rem;
    font-weight: 600;
     
  }
  .mensuelle{
    color:#9B9B9B;
    font-family: 'Montserrat bold' sans-serif;
    font-size:1.5rem;
    font-weight: 600;
     
  }
  .annuelle{
    color:#254484;
    font-family: 'Montserrat bold' sans-serif;
    font-size:1.5rem;
    font-weight: 600;
     
  }
  .annuelleChecked{
    color:#9B9B9B;
    font-family: 'Montserrat bold' sans-serif;
    font-size:1.5rem;
    font-weight: 600;
     
  }
  
  .garantieChoix{
    font-family: 'Montserrat medium' sans-serif;
    color:#2D2D2D;
    font-size: 1rem;
    font-weight: 500;
  
  }
  
  .container {
    display: block;
    position: relative;
    margin-left: 17rem;
    margin-top:0.5rem;
    margin-bottom: 2rem;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
   
  }
  
  
  
  
  
  .BoxPropositionChecked{
    border: 1px solid lightgray;
    width: 23rem;
    height:11rem;
    border-radius: 0.5rem;
    margin-inline: 1rem;
    cursor: pointer;
    background-color: rgba(236, 241, 249, 1);
    margin-bottom: 1rem;
    position: relative;
  }
  .BoxProposition{
    border: 1px solid lightgray;
    width: 23rem;
    height:11rem;
    border-radius: 0.5rem;
    margin-inline: 1rem;
    cursor: pointer;
    margin-bottom: 1rem;
    position: relative;
   
  }
  .proposition{
    color: #254484;
    font-family: '  Montserrat bold' sans-serif;
    font-weight: 600;
    font-size: 1.3rem;
    text-align: left  ;
    margin:0 ! important;
   padding-top: 2rem;
  }
  .propositionTotal{
    color: #254484;
    font-family: '  Montserrat bold' sans-serif;
    font-weight: 600;
    font-size: 2.5rem;
    text-align: left  ;
     
  
  }
  .propositionType{
    color: #254484;
    font-family: '  Montserrat bold' sans-serif;
    font-weight: 450;
    font-size: 1.5rem;
    text-align: center  ;
    margin-left: 1rem;
  
  }
  .buttons{
    background-color: rgba(202, 202, 202, 1) !important;
    width: 11rem;
    height:2.5rem;
    color:#254484 ! important;
    border-radius: 0.1rem;
    margin-top: 2rem !important;
    font-size: 0.8rem !important; 
    font-family: 'Montserrat regular', sans-serif !important;
    font-weight: 600 !important;
    margin-bottom: 2rem !important;
    margin-left: 0.5rem !important;
  }
  .retourButton{
    /* background-color:rgba(147, 5, 5, 1) !important; */
    color:white !important;
    width: 10rem !important;
    border: none !important;
    margin-bottom: 3rem !important;
    border-radius: 1rem !important;
    margin-top:5rem !important
    
  
  
  }
  .confirmButton{
    /* background-color: #254484 !important; */
    color:white !important;
    width: 10rem !important;
    border: none !important;
    margin-bottom: 3rem !important;
    border-radius: 1rem !important;
    margin-top:5rem !important
  }
  
  
  .RetourCol{
    display: flex;
    justify-content: flex-end;
  }
  
  
  
  .ButtonRadioChecked{
    margin-top:0.4rem;
    /* margin-left: 18.5rem; */
    position: absolute;
    right: 0.5rem;
  }
  .loaderContainer{
    display: flex;
    flex-direction: column;
    
    text-align: center;
  }
  .propositionBoxs{
    display: flex;
    justify-content: center;
  }