.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    /* padding: 2rem; */

}
.image{
    width: 10rem;
}
.maintenance{
    font-size: 2rem;
    font-weight: 700;
    margin-block:1rem
}
.disponiblite{
    font-size: 3rem; 
    font-weight: 500;
}
.section{
    padding:10rem;
}